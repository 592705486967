import {Accordion, Alert, Button, Col, Form, Modal, Row} from "react-bootstrap";
import {useForm} from "react-hook-form";
import { useSnapshot } from "valtio";
import React, {forwardRef, useEffect, useImperativeHandle, useMemo, useState} from "react";
import speedBusinessProxy from "../../../proxies/speedBusiness";
import RangeSlider from "../../../components/RangeSlider/RangeSlider";
import auth from "../../../services/auth";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCog} from "@fortawesome/free-solid-svg-icons";
import {
    dataRangeNbTours,
    dataRangeTempsParPersonne,
    dataRangeTempsPourChangerDePlace, dureeMinuteToTimeMysql, dureeTimeMysqlToMinute
} from "../../../views/SpeedBusinessView/ReglageStep/data";
import Select2 from "../../../components/Select2/Select2";
import useGetInvitation from "../../../requests/communication/invitations/useGetInvitations";
import LoadingView from "../../../views/LoadingView/LoadingView";
import useChangeSpeedBusiness from "../../../requests/communication/speedBusiness/useChangeSpeedBusiness";
import {BtSwal} from "../../../utils/alerts/sweetAlert";
import Select from "react-select";
import useGetParticipants from "../../../requests/communication/speedBusiness/useGetParticipants";
import {showErrorAlert} from "../../../utils/alerts/showErrorAlert";
import useDesinscrireParticipant from "../../../requests/communication/speedBusiness/useDesinscrireParticipant";
import toast from "react-hot-toast";

const ReglagesStep = forwardRef((_, ref) => {
    const speedSnap = useSnapshot(speedBusinessProxy);
    const participants = useGetParticipants();
    const {
        register, setValue, getValues, handleSubmit, reset, control,
        formState: { errors },
    } = useForm({
        defaultValues: speedBusinessProxy.speedBusiness,
    });
    const arrayNbpremier = [
        2, 3, 4, 5, 7, 11, 13, 17, 19, 23, 29, 31, 37, 41, 43, 47, 53, 59, 61, 67, 71, 73, 79, 83, 89, 97
    ];
    const desinscrireDestinataires = useDesinscrireParticipant({
        baseRoute: "rencontreSpeedBusiness",
        id: speedSnap?.speedBusiness?.id,
        onSuccess: (data) => {
            toast.success("Participants supprimés")
        },
        onError: (err) => {
            showErrorAlert("Erreur lors de l'enregistrement", err.message);
        },
    });
    const [nbPersonne, setNbPersonne] = useState(80);
    const [nom, setNom] = useState("");
    const [tempsParPersonne, setTempsParPersonne] = useState(3);
    const [tempsPourChangerPlace, setTempsPourChangerDePlace] = useState(3);
    const [nbTours, setNbTours] = useState(4);
    const [duree, setDuree] = useState(120);
    const [dureeEntracte, setDureeEntracte] = useState(0);
    const [privilegieDuree, setPrivilegieDuree] = useState(false);
    const [tauxRencontreVoulu, setTauxRencontreVoulu] = useState(100);
    const [reglagesAuto, setReglagesAuto] = useState(false);
    const [selectedRow, setSelectedRow] = useState(null);
    const [tableMaximum, setTableMaximum] = useState(11);
    const [nbMinTableMax, setNbMinTableMax] = useState(0);
    const [nbPersonneMaxParTable, setNbPersonneMaxParTable] = useState(10);
    const [nbPersonneMinParTable, setNbPersonneMinParTable] = useState(3);
    const [stateLimited, setStateLimited] = useState(false);
    const [souhaiteEntracte, setSouhaiteEntracte] = useState(false);
    const invitations = useGetInvitation({
        data: "idRencontreSpeedBusiness",
        searchable: true,
        orderable: true,
        search: {
            value: "NULL",
            regex: false,
        }
    });
    const invitationsFilterById = useGetInvitation({
        data: "id",
        searchOperator: "=",
        searchable: true,
        orderable: true,
        search: {
            value: speedSnap?.idInvitation ? speedSnap?.idInvitation : speedSnap?.speedBusiness?.idInvitation,
            regex: false,
        }
    });
    const [stateIdInvitation, setStateIdInvitation] = useState(null);
    const changeSpeed = useChangeSpeedBusiness();
    const [showCustomNbTour, setShowCustomNbTour] = useState(false);
    const [result, setResult] = useState(calculTempsTotalParToursSiNbTours(nbPersonne, nbTours, reglagesAuto, tableMaximum, nbPersonneMaxParTable, setStateLimited));
    const selectArraynbPremier = useMemo(() => {
        let list = [];
        for (var i = 2; i <= 100; i++) {
            list.push(i);
        }
        let tmpData = [];
        list.forEach((item) => {
            tmpData.push({
                value: item,
                label : item+" tables",
                // isDisabled:(!(nbPersonne/item >= nbPersonneMinParTable && nbPersonne/item <= nbPersonneMaxParTable && item*item >= nbPersonne))
                isDisabled:(!(item*item >= nbPersonne))
            })
        })
        if (nbPersonne && tmpData.find(item => item.value == tableMaximum)?.isDisabled){
            BtSwal.fire("Le nombre de tables est insuffisant", "" , "info")
            setTableMaximum("");
            setValue("nombreTablesMax", "")
        }
        return tmpData
    }, [nbPersonne, nbPersonneMinParTable, nbPersonneMaxParTable, tableMaximum])
    useEffect(() => {
        //-- calcul et mise a jour du tableau récap des solutions
        if (privilegieDuree && !reglagesAuto){
            setResult(nbToursEnFonctionDeLaDuree(nbPersonne, duree, tableMaximum, nbPersonneMaxParTable, setStateLimited))
        }else if (!privilegieDuree && !reglagesAuto){
            setResult(calculTempsTotalParToursSiNbTours(nbPersonne, nbTours, false, tableMaximum, nbPersonneMaxParTable, setStateLimited));
        }else{
            setResult(calculTempsTotalParToursSiNbTours(nbPersonne, nbTours, true, tableMaximum, nbPersonneMaxParTable, setStateLimited))
        }
    }, [nbPersonne, nbPersonneMinParTable, tempsParPersonne, tempsPourChangerPlace, nbTours, privilegieDuree, duree, tableMaximum, nbPersonneMaxParTable, reglagesAuto, dureeEntracte, tauxRencontreVoulu])
    useEffect(() => {
        //utile ?? -- je ne pense pas
        reset(speedSnap.speedBusiness ?? {
            idInvitation: null,
            auteur: auth.utilisateur,
            agence: auth.agence,
            dateCreation: Date.now(),
            dateProgrammationEnvoiEmail: null,
            nom: "",
            dureeChangementPlace: dureeMinuteToTimeMysql(tempsPourChangerPlace),
            dureeEntracte:dureeMinuteToTimeMysql(dureeEntracte),
            dureeParoleParPersonne: dureeMinuteToTimeMysql(tempsParPersonne),
            dureeTotale: dureeMinuteToTimeMysql(duree),
            tauxRencontre: tauxRencontreVoulu,
            nombreTours: nbTours,
            nombrePersonnesMaxParTable: nbPersonneMaxParTable,
            nombrePersonnesMinParTable:nbPersonneMinParTable,
            nombreTablesMax: tableMaximum,
            nombreTablesChoisies: null,
            tabNomsTables: null,
            nombrePersonnesPrevues: nbPersonne,
            calculAutoDureeTotale: reglagesAuto,
            tabPreferencesDecision: [
                {
                id: 0,
                libelle: "Nombre de tours",
                actif: true
                },
                {
                    id: 1,
                    libelle: "Durée totale",
                    actif: false
                },
                {
                    id: 2,
                    libelle: "Taux de rencontre",
                    actif: false
                }
            ],
            //todo voir ou le service de sms sera choisi...
            utilisationServiceSms: false,
            id:null,
        });
        if (speedSnap.speedBusiness){
            setValue("tauxRencontre", getValues("tauxRencontre")*100)
            setValue("dureeEntracte", dureeTimeMysqlToMinute(getValues("dureeEntracte")))
            setValue("dureeTotale", dureeTimeMysqlToMinute(getValues("dureeTotale")))
        }
    }, [reset, speedBusinessProxy.speedBusiness, nom]);
    // initialisation des états par rapport au speed
    useEffect(() => {
        if (speedBusinessProxy.speedBusiness && speedBusinessProxy.speedBusiness.id){
            setNom(speedBusinessProxy.speedBusiness.nom)
            setTempsPourChangerDePlace(dureeTimeMysqlToMinute(speedBusinessProxy.speedBusiness.dureeChangementPlace));
            setDureeEntracte(dureeTimeMysqlToMinute(speedBusinessProxy.speedBusiness.dureeEntracte));
            setDuree(dureeTimeMysqlToMinute(speedBusinessProxy.speedBusiness.dureeTotale))
            setTempsParPersonne(dureeTimeMysqlToMinute(speedBusinessProxy.speedBusiness.dureeParoleParPersonne));
            setTauxRencontreVoulu(speedBusinessProxy.speedBusiness.tauxRencontre * 100);
            setNbTours(speedBusinessProxy.speedBusiness.nombreTours);
            setNbPersonneMaxParTable(speedBusinessProxy.speedBusiness.nombrePersonnesMaxParTable);
            setNbPersonneMinParTable(speedBusinessProxy.speedBusiness.nombrePersonnesMinParTable);
            setTableMaximum(speedBusinessProxy.speedBusiness.nombreTablesMax);
            setNbPersonne(speedBusinessProxy.speedBusiness.nombrePersonnesPrevues);
            setReglagesAuto(speedBusinessProxy.speedBusiness.tabPreferencesDecision[2].actif)
            setPrivilegieDuree(speedBusinessProxy.speedBusiness.tabPreferencesDecision[1].actif)
            setStateIdInvitation(speedBusinessProxy.speedBusiness.idInvitation)
            setSelectedRow({nbTables: speedBusinessProxy.speedBusiness.nombreTablesChoisies, nbPersonnesParTable: speedBusinessProxy.speedBusiness.nombrePersonnesParTableChoisies});
            setSouhaiteEntracte(dureeTimeMysqlToMinute(speedBusinessProxy.speedBusiness.dureeEntracte) > 0);
        }
    }, [speedBusinessProxy.speedBusiness]);

    useImperativeHandle(ref, () => ({
        save: async () => {
            let done = false;
            // bloc vérification participants
            if (speedSnap?.speedBusiness?.id){
                let dataParticipants = await participants.mutateAsync(speedSnap?.speedBusiness?.id)
                if (selectedRow && dataParticipants?.nbLignesTotales > selectedRow.nbTables*selectedRow.nbPersonnesParTable){
                    const resultBt = await BtSwal.fire({
                        title: "Le nombre de participants inscrit est supérieur au nombre de personnes maximum",
                        text: "Voulez-vous que le système supprime tous les participants ?",
                        icon: 'warning',
                        showDenyButton: true,
                        denyButtonText: 'Non',
                        confirmButtonText: 'Oui',
                        customClass: {
                            confirmButton: 'btn btn-secondary',
                            denyButton: 'btn btn-danger',
                        },
                    });
                    if (resultBt.isConfirmed){
                        desinscrireDestinataires.mutate({
                            idPersonnes: [],
                            isInclusif:true
                        });
                    } else {
                        return false;
                    }
                }
            }
            await handleSubmit(async (data) => {
                try {
                    if (!selectedRow){
                        BtSwal.fire("Merci de sélectionner une proposition dans le tableau des résultats", "", "info" )
                        done = false;
                    }else if (!Object.values(result).find(item => (item.nbTables == selectedRow?.nbTables && item.nbPersonnesParTable == selectedRow?.nbPersonnesParTable))){
                        BtSwal.fire("Merci de sélectionner une proposition dans le tableau des résultats", "", "info" )
                        done = false;
                    }else {
                        let currentSelectedRow = Object.values(result).find(item => (item.nbTables == selectedRow?.nbTables && item.nbPersonnesParTable == selectedRow?.nbPersonnesParTable));
                        data.dureeChangementPlace = dureeMinuteToTimeMysql(tempsPourChangerPlace)
                        data.dureeEntracte = dureeMinuteToTimeMysql(dureeEntracte)
                        data.dureeTotale = dureeMinuteToTimeMysql(duree)
                        data.dureeParoleParPersonne = dureeMinuteToTimeMysql(tempsParPersonne)
                        data.tauxRencontre = tauxRencontreVoulu/100
                        data.nombreTours = currentSelectedRow.nbTours
                        data.nombrePersonnesMaxParTable = nbPersonneMaxParTable
                        data.nombrePersonnesMinParTable = nbPersonneMinParTable
                        data.nombrePersonnesParTableChoisies = selectedRow.nbPersonnesParTable
                        data.nombreTablesMax = tableMaximum
                        data.nombrePersonnesPrevues = nbPersonne
                        data.idInvitation = stateIdInvitation
                        data.nombreTablesChoisies = selectedRow.nbTables
                        if (privilegieDuree && !reglagesAuto){
                            data.tabPreferencesDecision = [
                                {
                                    id: 0,
                                    libelle: "Nombre de tours",
                                    actif: false
                                },
                                {
                                    id: 1,
                                    libelle: "Durée totale",
                                    actif: true
                                },
                                {
                                    id: 2,
                                    libelle: "Taux de rencontre",
                                    actif: false
                                }
                            ]
                        }else if (!privilegieDuree && !reglagesAuto){
                            data.tabPreferencesDecision = [
                                {
                                    id: 0,
                                    libelle: "Nombre de tours",
                                    actif: true
                                },
                                {
                                    id: 1,
                                    libelle: "Durée totale",
                                    actif: false
                                },
                                {
                                    id: 2,
                                    libelle: "Taux de rencontre",
                                    actif: false
                                }
                            ]
                        }else {
                            data.tabPreferencesDecision = [
                                {
                                    id: 0,
                                    libelle: "Nombre de tours",
                                    actif: false
                                },
                                {
                                    id: 1,
                                    libelle: "Durée totale",
                                    actif: false
                                },
                                {
                                    id: 2,
                                    libelle: "Taux de rencontre",
                                    actif: true
                                }
                            ]
                        }

                        const resultMutate = await changeSpeed.mutateAsync(data);
                        speedBusinessProxy.speedBusiness = resultMutate;
                        done = true;
                    }
                } catch {}
            })();
            return done;
        },
    }));
    function calcNbPersonneParTable(nbPersonnes = 160){
        let arrayNbTablesKeyPersonnePerTableValue = {};
        let lastTmp = null;
        arrayNbpremier.forEach((nbPremier,index) => {
            if (nbPersonnes <= (nbPremier*nbPremier)){
                let tmp = Math.ceil(nbPersonnes/nbPremier)
                if ((nbPersonnes/nbPremier) >= nbPersonneMinParTable && tmp != lastTmp){
                    arrayNbTablesKeyPersonnePerTableValue[nbPremier] = tmp;
                    lastTmp = tmp;
                }
            }
        })
        return arrayNbTablesKeyPersonnePerTableValue;
    }
    function calcTempsParTours(nbPersonnes = 160, maxTablestmp, maxPersParTable, setTmpLimited){
        let arrayNbTablesKeyPersonnePerTableValue = calcNbPersonneParTable(nbPersonnes);
        let arrayTablesPersonnesDuree = {}
        let index = 0;
        let limited = true;
        for (const [key, value] of Object.entries(arrayNbTablesKeyPersonnePerTableValue)){
            let nbPersonneParTable = key <= maxPersParTable ? key : maxPersParTable;
            if (parseInt(key) <= maxTablestmp && value <= maxPersParTable){
                arrayTablesPersonnesDuree[index] = {
                    nbTables: parseInt(key),
                    nbPersonnesParTable: nbPersonneParTable,
                    dureeParTour: value*tempsParPersonne
                }
                limited = false;
            }
            index++;
        }
        //todo voir avec yannick pour le nombre de tour max pour les groupe de 2 (il va certainement devoir faire un exception)
        if (nbPersonnes%2 == 0 && nbPersonnes/2 <= maxTablestmp && 2 >= nbPersonneMinParTable){
            limited = false;
            arrayTablesPersonnesDuree[index] = {
                nbTables: parseInt(nbPersonnes/2),
                nbPersonnesParTable: 2,
                dureeParTour: 2*tempsParPersonne
            }
        }
        if (limited && !stateLimited){
            setTmpLimited(true)
        }else if (!limited && stateLimited){
            setTmpLimited(false)
        }
        return arrayTablesPersonnesDuree;
    }
    function calculTempsTotalParToursSiNbTours(nbPersonnes, nbTours, auto, maxTablestmp, maxPersParTable, setTmpLimited){
        let arrayTablesPersonnesDuree = calcTempsParTours(nbPersonnes, maxTablestmp, maxPersParTable, setTmpLimited);
        let arrayFinal = {};
        for (const [key, value] of Object.entries(arrayTablesPersonnesDuree)){
            let tmpNbTours = auto ? Math.round((value.nbTables+1)*(tauxRencontreVoulu/100)) : nbTours
            if (((nbPersonnes%value.nbTables) == 1 && Math.trunc((tmpNbTours/(value.nbTables+1))*100) == 100) || (maxPersParTable < value.nbTables && Math.trunc((tmpNbTours/(value.nbTables+1))*100) >= 100)){
                tmpNbTours = value.nbTables;
            }
            let nbToursLocal = value.nbTables+1 >= tmpNbTours ? tmpNbTours : value.nbTables+1;
            arrayFinal[key] = {
                ...value,
                nbTours: nbToursLocal,
                tempsTotal: (value.dureeParTour * nbToursLocal) + (tempsPourChangerPlace * (nbToursLocal-1)) + dureeEntracte
            }
        }
        return arrayFinal;
    }
    function nbToursEnFonctionDeLaDuree(nbPersonnes, duree, maxTablestmp, maxPersParTable, setTmpLimited){
        let arrayTablesPersonnesDuree = calcTempsParTours(nbPersonnes, maxTablestmp, maxPersParTable, setTmpLimited);
        let arrayFinal = {};
        for (const [key, value] of Object.entries(arrayTablesPersonnesDuree)){
            let tmpNbTours = ((Math.floor((duree+(tempsPourChangerPlace)) / (value.dureeParTour+(tempsPourChangerPlace)))) > value.nbTables + 1) ? value.nbTables + 1 : (Math.floor((duree+(tempsPourChangerPlace)) / (value.dureeParTour+(tempsPourChangerPlace))));
            if (((Math.floor((duree+(tempsPourChangerPlace)) / (value.dureeParTour+(tempsPourChangerPlace)))) < value.nbTables + 1)){
                arrayFinal[key] = {
                    ...value,
                    nbTours: tmpNbTours,
                    tempsTotal: value.dureeParTour * Math.floor((duree+tempsPourChangerPlace) / (value.dureeParTour+tempsPourChangerPlace)) + tempsPourChangerPlace * (Math.floor((duree+tempsPourChangerPlace) / (value.dureeParTour+tempsPourChangerPlace))-1) + dureeEntracte
                }
            }else {
                arrayFinal[key] = {
                    ...value,
                    nbTours: tmpNbTours,
                    tempsTotal: value.dureeParTour * tmpNbTours + (tempsPourChangerPlace * (tmpNbTours-1)) + dureeEntracte
                }
            }
        }
        return arrayFinal;
    }
    const dataInvitation =  useMemo(() => {
        let tmpDataInvitation = invitations?.data?.data.map((response, index) => (
            {
                id : response.id,
                text : response.sujet
            }));

        if ((speedSnap?.speedBusiness?.idInvitation || speedSnap?.idInvitation) && invitations?.data && invitationsFilterById?.data?.data){
            tmpDataInvitation.push({
                id : invitationsFilterById?.data?.data[0]?.id,
                text : invitationsFilterById?.data?.data[0]?.sujet
            });
            setStateIdInvitation(invitationsFilterById?.data?.data[0]?.id);
        }
        return tmpDataInvitation;
    }, [invitations?.data, invitationsFilterById?.data, speedSnap?.speedBusiness?.id]);


    if (invitations.isLoading || invitations.isFetching || invitationsFilterById.isLoading) {
        return <LoadingView/>
    }
    function changeInvitation(val) {
        setStateIdInvitation(val[0])
    }

    return <div className='mw-800px m-auto'>
        <Row>
            <Col lg={6} md={6}>
                <Form.Group>
                    <Form.Label className='required'>Nom du speed business</Form.Label>
                    <Form.Control defaultValue={nom}
                                  onClick={(event) => {
                                      setNom(event.target.value)
                                  }}
                                  type="text"
                                  className='form-control-solid' width={10} {...register('nom', {required: true})} placeholder='Nom du speed business'/>
                </Form.Group>
                {errors.nom && <Form.Text className='text-danger'>Ce champ est requis</Form.Text>}
            </Col>
            <Col lg={6} md={6}>
                <Form.Group>
                    <Form.Label>{!(speedSnap.speedBusiness?.idInvitation || speedSnap?.idInvitation) ? "Lier le speed à une invitation" : "Modifier l'invitation lié au speed"} </Form.Label>
                    <Select2
                        solid={!!speedSnap.idInvitation}
                        disabled={!!speedSnap.idInvitation}
                        placeholder={"Choisir une invitation"}
                        data={dataInvitation}
                        minimumResultsForSearch={3}
                        onChange={changeInvitation}
                        selected={speedSnap?.idInvitation ? speedSnap?.idInvitation : speedBusinessProxy.speedBusiness?.idInvitation}
                    />
                </Form.Group>
            </Col>
        </Row>
        <div className="d-flex justify-content-center text-center">
            <div>
                <div style={{
                    marginBottom: "0.5rem",
                    marginTop: "0.5rem",
                    fontSize: "1.05rem",
                    fontWeight: "500",
                    color: "#403d38"
                }}>Quelle est votre préférence ? </div>
                <div className="ms-2 btn-group" data-kt-buttons="true"
                     data-kt-buttons-target="[data-kt-button]">
                    <label
                        style={{
                            width: 160
                        }}
                        className={`small p-2 btn btn-outline-secondary text-muted text-hover-white text-active-white btn-outline btn-active-success ${(!privilegieDuree && !reglagesAuto) ? `active` : ``}`}
                        data-kt-button="true">
                        <input
                            onClick={(err) => {
                                setPrivilegieDuree(false)
                                setReglagesAuto(false)
                            }}
                            className="btn-check" type="radio" name="method" value={true}/>
                        <span style={{fontSize: '10px'}}>Je choisi le nombre de tour</span></label>
                    <label
                        style={{
                            width: 160
                        }}
                        className={`small p-2 btn btn-outline-secondary text-muted text-hover-white text-active-white btn-outline btn-active-success ${(privilegieDuree && !reglagesAuto) ? `active` : ``}`}
                        data-kt-button="true">
                        <input
                            onClick={(err) => {
                                setPrivilegieDuree(true)
                                setReglagesAuto(false)
                            }}
                            className="btn-check" type="radio" name="method" value={false}/>
                        <span style={{fontSize: '10px'}}>Je choisi la durée totale</span></label>
                    <label
                        style={{
                            width: 200
                        }}
                        className={`small p-2 btn btn-outline-secondary text-muted text-hover-white text-active-white btn-outline btn-active-success ${reglagesAuto ? `active` : ``}`}
                        data-kt-button="true">
                        <input
                            onClick={(err) => {
                                setReglagesAuto(true)
                                setPrivilegieDuree(false)
                            }}
                            className="btn-check" type="radio" name="method" value={false}/>
                        <span style={{fontSize: '10px'}}>Je privilégie les rencontres</span></label>
                </div>
            </div>
        </div>
        <Row className="mt-5 d-flex justify-content-center">
            {(!privilegieDuree && !reglagesAuto) &&
                <><Col className="mt-10" lg={11}>
                    <Form.Label className='required  mb-5'>Nombre de tours</Form.Label>
                    <RangeSlider range={nbTours + ""} setRange={setNbTours} rangeValues={Object.values(dataRangeNbTours).find(item => item.valeur == nbTours) ? dataRangeNbTours : {
                        ...dataRangeNbTours,
                        13: {
                            text:nbTours,
                            valeur: nbTours
                        }
                    }} classContainer={"mt-5 position-relative"}/>
                </Col>
                <Col className="justify-content-center align-items-end d-flex" lg={1}>
                    <Button onClick={() => {
                        //open modal set tours
                        setShowCustomNbTour(true);
                    }} className="fa fa-plus"></Button>
                </Col>
                </>
            }
            {privilegieDuree &&
                <Col lg={4} md={4} sm={6}>
                    <Form.Group>
                        <Form.Label className='required'>Durée totale en minutes</Form.Label>
                        <div className="input-group input-group mb-5">
                            <Form.Control defaultValue={duree}
                                          onClick={(event) => {
                                              setDuree(parseInt(event.target.value))
                                          }}
                                          onKeyUp={(event) => {
                                              setDuree(parseInt(event.target.value))
                                          }} {...register('dureeTotale', {required: true, valueAsNumber: true,})} type="number"
                                          className='form-control-solid' width={10} placeholder='Durée totale'/>
                            <label style={{
                                fontSize:10
                            }} className="input-group-text bg-white">minutes au total</label>
                        </div>
                    </Form.Group>
                    {errors.duree && <Form.Text className='text-danger'>Ce champ est requis</Form.Text>}
                </Col>
            }
            {reglagesAuto &&
                <Col lg={4} md={4} sm={6}>
                    <Form.Group>
                        <Form.Label className='required'>Taux de rencontre souhaité</Form.Label>
                        <div className="input-group input-group mb-5">
                            <Form.Control defaultValue={tauxRencontreVoulu}
                                          onClick={(event) => {
                                              setTauxRencontreVoulu(parseInt(event.target.value))
                                          }}
                                          onKeyUp={(event) => {
                                              setTauxRencontreVoulu(parseInt(event.target.value))
                                          }} {...register('tauxRencontre', {required: true, valueAsNumber: true,})} type="number"
                                          className='form-control-solid' width={10} placeholder='taux souhaité'/>
                            <label style={{
                                fontSize:10
                            }} className="input-group-text bg-white">%</label>
                        </div>
                    </Form.Group>
                    {errors.tauxRencontre && <Form.Text className='text-danger'>Ce champ est requis</Form.Text>}
                </Col>
            }
        </Row>
        <Row className='mt-5'>
            <Col lg={4} md={4} sm={6}>
                <Form.Group>
                    <div className="input-group input-group mb-5">
                        <Form.Control defaultValue={nbPersonne}
                                      onClick={(event) => {
                                          setNbPersonne(parseInt(event.target.value))
                                      }}
                                      onKeyUp={(event) => {
                                          setNbPersonne(parseInt(event.target.value))
                                      }} {...register('nombrePersonnesPrevues', {required: true, valueAsNumber: true,})} type="number"
                                      className='form-control-solid' width={10} placeholder='Nombre de personnes'/>
                        <label className="input-group-text bg-white" style={{
                            fontSize:10
                        }}>personnes prévues</label>
                    </div>
                </Form.Group>
                {errors.nombrePersonnesPrevues && <Form.Text className='text-danger'>Ce champ est requis</Form.Text>}
            </Col>
            <Col lg={4} md={4} sm={6}>
                <Form.Group>
                    <div className="input-group input-group mb-5">
                        <Select
                            className='form-control-solid'
                            defaultValue={getValues("nombreTablesMax") ? selectArraynbPremier.find(item => item.value == getValues("nombreTablesMax") ) : "" }
                            options={selectArraynbPremier}
                            value={getValues("nombreTablesMax") ? selectArraynbPremier.find(item => item.value == getValues("nombreTablesMax") ) : "" }
                            onChange={(selected) => {
                                setValue("nombreTablesMax", selected.value)
                                setTableMaximum(parseInt(selected.value))
                                // setNbMinTableMax(Math.ceil(nbPersonne/selected.value))
                                if (getValues('nombrePersonnesMaxParTable') < Math.ceil(nbPersonne/selected.value)) {
                                    setValue('nombrePersonnesMaxParTable', Math.ceil(nbPersonne/selected.value))
                                    setNbPersonneMaxParTable(Math.ceil(nbPersonne/selected.value))
                                }
                            }}
                        />
                        <label className="input-group-text bg-white" style={{
                            fontSize:10
                        }}>tables disponibles</label>
                    </div>
                </Form.Group>
                {errors.nombreTablesMax && <Form.Text className='text-danger'>Ce champ est requis</Form.Text>}
            </Col>
            <Col lg={4} md={4} sm={6}>
                <Form.Group>
                    <div className="input-group input-group mb-5">
                        <Form.Control defaultValue={nbPersonneMaxParTable}
                                      onClick={(event) => {
                                          setNbPersonneMaxParTable(parseInt(event.target.value))
                                      }}
                                      onKeyUp={(event) => {
                                          setNbPersonneMaxParTable(parseInt(event.target.value))
                                      }} type="number"
                                      className='form-control-solid' {...register('nombrePersonnesMaxParTable', {required: true, valueAsNumber: true, min: nbMinTableMax})} width={10} placeholder='Nombre de personnes'/>
                        <label className="input-group-text bg-white" style={{
                            fontSize:10
                        }}>personnes par table maximum</label>
                    </div>
                </Form.Group>
                {errors.nombrePersonnesMaxParTable && <Form.Text className='text-danger'>Ce champ est requis. Il faut au minimum {nbMinTableMax} personnes par table.</Form.Text>}
            </Col>
        </Row>
        <Accordion>
            <Accordion.Item eventKey="0">
                <Accordion.Header><FontAwesomeIcon icon={faCog}/> <h2 style={{
                    margin: 0,
                    padding:0,
                    marginLeft: 10
                }}>Plus de paramètres</h2></Accordion.Header>
                <Accordion.Body>
                    <Row>
                        <Col className="mt-3" lg={6}>
                            <Form.Label className='required mb-5'>Temps par personne (en seconde)</Form.Label>
                            <RangeSlider range={tempsParPersonne + ""} setRange={setTempsParPersonne} rangeValues={dataRangeTempsParPersonne} classContainer={"mt-5 position-relative"}/>
                        </Col>
                        <Col className="mt-3" lg={6}>
                            <Form.Label className='required  mb-5'>Temps pour changer de table (en seconde)</Form.Label>
                            <RangeSlider range={tempsPourChangerPlace + ""} step={0.5}  setRange={setTempsPourChangerDePlace} rangeValues={dataRangeTempsPourChangerDePlace} classContainer={"mt-5 position-relative"}/>
                        </Col>
                        <Col lg={4} md={6} sm={12}>
                            <Form.Group>
                                <div className="input-group input-group mb-5">
                                    <Form.Control defaultValue={nbPersonneMinParTable}
                                                  {...register('nombrePersonnesMinParTable', {required: true, valueAsNumber: true, min:2})}
                                                  onClick={(event) => {
                                                      if (event.target.value < 2) {
                                                          setValue("nombrePersonnesMinParTable", "2")
                                                          BtSwal.fire("Le nombre de personne par table minimum ne peut pas être en dessous de 2.", "", "warning")
                                                      }else {
                                                          setNbPersonneMinParTable(parseInt(event.target.value))
                                                      }
                                                  }}
                                                  onKeyUp={(event) => {
                                                      if (event.target.value < 2) {
                                                          setValue("nombrePersonnesMinParTable", "2")
                                                          BtSwal.fire("Le nombre de personne par table minimum ne peut pas être en dessous de 2.", "", "warning")
                                                      }else {
                                                          setNbPersonneMinParTable(parseInt(event.target.value))
                                                      }
                                                  }} type="number"
                                                  className='form-control-solid'
                                                  width={10} placeholder='Nombre de personnes'/>
                                    <label className="input-group-text bg-white" style={{
                                        fontSize:10
                                    }}>personnes par table minimum</label>
                                </div>
                            </Form.Group>
                            {errors.nombrePersonnesMinParTable && <Form.Text className='text-danger'>Ce champ est requis. Le minimum est de 2 personnes.</Form.Text>}
                        </Col>
                        <Col lg={12} md={12} sm={12}>
                            <Form.Check
                                className="mt-5 me-0"
                                inline
                                type='switch'
                                label=""
                                checked={souhaiteEntracte}
                                onClick={(val)  => {
                                    if (souhaiteEntracte) {
                                        setDureeEntracte(0);
                                        setValue("dureeEntracte", 0);
                                    }
                                    setSouhaiteEntracte(!souhaiteEntracte)
                                }}
                            />
                            <Form.Label className="m-0">Prévoir une entracte à mis parcours</Form.Label>
                            {souhaiteEntracte &&
                                <Col className="d-flex justify-content-center" >
                                    <Form.Group style={{
                                        width: 200
                                    }}>
                                        <Form.Label className='required'>Durée entracte</Form.Label>
                                        <div className="input-group input-group mb-5">
                                            <Form.Control defaultValue={dureeEntracte}
                                                          onClick={(event) => {
                                                              setDureeEntracte(parseInt(event.target.value) ? parseInt(event.target.value) : 0 )
                                                          }}
                                                          onKeyUp={(event) => {
                                                              setDureeEntracte(parseInt(event.target.value) ? parseInt(event.target.value) : 0 )
                                                          }} type="number"
                                                          className='form-control-solid' width={10} {...register('dureeEntracte', {required: true, valueAsNumber: true,})} placeholder='Durée entracte'/>
                                            <label className="input-group-text bg-white">minutes</label>
                                        </div>
                                        {errors.dureeEntracte && <Form.Text className='text-danger'>Ce champ est requis</Form.Text>}

                                    </Form.Group>

                                </Col>
                            }
                        </Col>

                    </Row>
                </Accordion.Body>
            </Accordion.Item>
        </Accordion>


        <table className="table table-row-bordered mt-8">
            <thead style={{
                backgroundColor: "lightgray"
            }}>
            <tr style={{
                fontWeight: "bold"
            }} className="text-center">
                <th scope="col">Nombre de tables</th>
                <th scope="col">Table de</th>
                <th scope="col">Temps par tours</th>
                <th scope="col">Nb tours</th>
                <th scope="col">Temps total</th>
                <th scope="col">Pourcentage de rencontre</th>
                <th scope="col">Minimum de personne</th>
                <th scope="col" colSpan={2}>Maximum de personne</th>
                <th scope="col"></th>
            </tr>
            </thead>
            <tbody>
            {result && Object.values(result).map(row => {
                if (row.nbTours){
                    return <tr style={{
                        backgroundColor: ((row.nbTables == selectedRow?.nbTables && row.nbPersonnesParTable == selectedRow?.nbPersonnesParTable) ? "var(--primary-color)" : "white"),
                        color: ((row.nbTables == selectedRow?.nbTables && row.nbPersonnesParTable == selectedRow?.nbPersonnesParTable) ? "white" : "black"),
                        cursor: ((row.nbTables == selectedRow?.nbTables && row.nbPersonnesParTable == selectedRow?.nbPersonnesParTable) ? "pointer" : "default")
                    }}
                               onClick={async () => {
                                   setSelectedRow(row)
                                  // Condition obselete avec le nouveau système

                                   // if (nbPersonne / 2 == row.nbTables) {
                                   //     const result = await BtSwal.fire({
                                   //         title: "Souhaitez vous faire exclusivement des groupes de 2 personnes ?",
                                   //         text: "Le nombre de personnes par table sera limité à 2",
                                   //         icon: 'warning',
                                   //         showDenyButton: true,
                                   //         denyButtonText: 'Non',
                                   //         confirmButtonText: 'Oui',
                                   //         customClass: {
                                   //             confirmButton: 'btn btn-secondary',
                                   //             denyButton: 'btn btn-danger',
                                   //         },
                                   //     });
                                   //     if (result.isConfirmed) {
                                   //          setNbPersonneMinParTable(2);
                                   //          setNbPersonneMaxParTable(2);
                                   //          setValue("nombrePersonnesMaxParTable", 2);
                                   //          setValue("nombrePersonnesMinParTable", 2);
                                   //     }
                                   // }
                               }}
                               onMouseLeave={(event) => {
                                   if (selectedRow?.nbTables !== row.nbTables || row.nbPersonnesParTable != selectedRow?.nbPersonnesParTable) {
                                       event.currentTarget.style.backgroundColor = "white"
                                       event.currentTarget.style.color = "black"
                                       event.currentTarget.style.cursor = "default"
                                   }
                               }}
                               onMouseOver={(event) => {
                                   if (selectedRow?.nbTables !== row.nbTables || row.nbPersonnesParTable != selectedRow?.nbPersonnesParTable) {
                                       event.currentTarget.style.backgroundColor = "var(--primary-color)"
                                       event.currentTarget.style.color = "white"
                                       event.currentTarget.style.cursor = "pointer"
                                   }
                               }} className="text-center">
                        <th className="" scope="row">{row.nbTables}</th>
                        <td>{row.nbPersonnesParTable} <i className="fa fa-user" style={{
                            color: "rgb(33, 31, 28)"
                        }}></i></td>
                        <td>{Math.trunc(row.dureeParTour) + "m" + (row.dureeParTour % 1) * 60 + "s"}</td>
                        <td>{row.nbTours}</td>
                        <td>{Math.floor(row.tempsTotal / 60) + "h" + Math.trunc(row.tempsTotal % 60 > 9 ? row.tempsTotal % 60 : "0" + row.tempsTotal % 60) + "m" + (((row.tempsTotal % 60) % 1) * 60) + "s"}</td>
                        <td>{Math.trunc((row.nbTours/(row.nbTables+1))*100)+"%"}</td>
                        <td>{row.nbTables*nbPersonneMinParTable}</td>
                        <td>{row.nbPersonnesParTable*row.nbTables}</td>
                        {(row.nbTables == selectedRow?.nbTables && row.nbPersonnesParTable == selectedRow?.nbPersonnesParTable) && <td
                            onClick={(event) => {
                                event.stopPropagation()
                                setSelectedRow(null);
                            }}><i style={{
                            color: "red"
                        }} className="fa fa-times me-3"></i></td>}
                        {selectedRow?.nbTables !== row && <td></td>}
                    </tr>
                }
            })}
            {stateLimited &&
                <tr>
                    <th className="" colSpan={6} scope="row"><Alert variant="danger">Le nombre de personnes par table maximum doit être au moin de {nbMinTableMax}</Alert></th>
                </tr>
            }
            </tbody>
        </table>
        <Modal onHide={() => {
            setShowCustomNbTour(false);
        }} show={showCustomNbTour}>
            <Modal.Body>
                <Form.Label>Nombre de tour</Form.Label>
                <Form.Control defaultValue={nbTours} id="customNbTourSpeedBusiness">

                </Form.Control>
                <Button
                    className="mt-2"
                    onClick={() => {
                        setNbTours(document.getElementById("customNbTourSpeedBusiness").value);
                        setShowCustomNbTour(false);
                    }}
                >Confirmer</Button>
            </Modal.Body>
        </Modal>
    </div>
        ;
});

export default ReglagesStep;