import { Editor } from '@tinymce/tinymce-react';
import {useEffect, useRef, useState} from 'react';

import './RichTextEditor.css'

import 'tinymce/tinymce';

// Theme
import 'tinymce/themes/silver';
// Toolbar icons
import 'tinymce/icons/default';
// Editor styles
import 'tinymce/skins/ui/oxide/skin.min.css';

import 'tinymce/plugins/link';
import 'tinymce/plugins/code';
import 'tinymce/plugins/paste';
import 'tinymce/plugins/autolink';
import 'tinymce/plugins/noneditable';
import 'tinymce/plugins/table';
import 'tinymce/plugins/autoresize';
import {linkUrlTinyMce} from "../../../../functions/linkUrlTinyMce";
import fileManagerProxy from "../../../../proxies/fileManager";
import {useSnapshot} from "valtio";
import $ from "jquery";
import useUploadFile from "../../../../requests/upload/useUploadFile";
import {iconLienPj} from "../../../../assets/icons/svgTinymce";
import {BtSwal} from "../../../../utils/alerts/sweetAlert";
import motsClesProxy from "../../../../proxies/motsClesProxy";

function RichTextEditor({ value, onChange, id }) {
    const editorRef = useRef(null);
    const fileManagerSnap = useSnapshot(fileManagerProxy)
    const uploadFile = useUploadFile({ type: "userfile" });
    const [pastedLink, setPastedLink] = useState("");
    const motsClesSnap = useSnapshot(motsClesProxy);

    if (uploadFile.data != null) {
        $(".tox-dialog__footer-end .tox-button").eq(1).prop("disabled",false);
        $(".tox-textfield").first().val(uploadFile.data.urlOriginale)
        if ($(".tox-textfield").eq(1).val() == ""){
            $(".tox-textfield").eq(1).val("Cliquer ici pour ouvrir la pièce jointe.")
        }
        $(".tox-dialog__footer-end button").eq(1).trigger('click')
    }

    useEffect(() => {
        pastedLink.replaceAll("\n", " ").split(" ").forEach((link) => {
            if (isValidHttpUrl(link)){
                BtSwal.fire('Merci d\'utiliser l\'option lien afin ajouter un lien au mail.', '', 'info')
                setTimeout(() => {
                    editorRef.current.setContent(editorRef.current.getContent().replaceAll(link, "<a href='"+link+"'>Cliquer ici</a>"))
                    setPastedLink("");
                }, 500)
            }
        })
    }, [pastedLink])

    function isValidHttpUrl(string) {
        let url;

        try {
            url = new URL(string);
        } catch (_) {
            let checkUrlMotCle = string.slice(0,4)
            return string && (checkUrlMotCle == "{url" || checkUrlMotCle == "{lis" || checkUrlMotCle == "{lie");
        }

        return url.protocol === "https:" || url.protocol === "http:";
    }



        return <div id="editableDiv">
        <Editor
            onInit={(evt, editor) => editorRef.current = editor}
            value={value}
            onEditorChange={onChange}
            inline={true}
            // id={"tinyMCEARecup"+id}
            init={{
                skin: false,
                // height: 50,
                selector: '#editableDiv',
                language : 'fr_FR',
                language_url : '/lang/tinymce/fr_FR/langs/fr_FR.js',
                menubar: false,
                plugins: ["link autolink noneditable autoresize table paste code"],
                paste_as_text: true,
                default_link_target: '_blank',
                toolbar: 'undo redo | fontsizeselect | ' +
                    'bold italic forecolor | mybutton | alignleft aligncenter ' +
                    'alignright alignjustify | bullist numlist outdent indent | ' +
                    'removeformat | help | link unlink table code',
                toolbar_mode: 'sliding',
                width : 500,
                setup: function (editor) {
                    /* Menu items are recreated when the menu is closed and opened, so we need
                       a variable to store the toggle menu item state. */
                    var toggleState = false;

                    editor.ui.registry.addIcon('link', iconLienPj);

                    editor.on('init',function(e) {
                        editor.windowManager.oldOpen = editor.windowManager.open;
                        editor.windowManager.open = function (t, r) {
                            var modal = this.oldOpen.apply(this, [t, r]);  // call original

                            linkUrlTinyMce(t, isValidHttpUrl, id, uploadFile)

                            return modal;
                        }

                        $(".mce-content-body").on("paste", function (e){
                            setPastedLink(e.originalEvent.clipboardData.getData('Text'))
                        })
                    });

                    /* example, adding a toolbar menu button */
                    editor.ui.registry.addMenuButton('mybutton', {
                        text: 'Mots clés',
                        fetch: function (callback) {
                            let data = [];
                            motsClesSnap.motsCles.forEach((item) => {
                                data.push({
                                    type: item.type,
                                    text: item.text,
                                    onAction: function () {
                                        editor.insertContent(item.insert);
                                    }
                                })
                            })
                            callback(data);
                        }
                    });

                },

            }}
        />
    </div>;
}

export default RichTextEditor;