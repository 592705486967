import {useEffect, useState} from "react";
import {URLWebSocketSpeed} from "../../../config";
import {v4 as uuidv4} from "uuid";
import speedBusinessProxy from "../../../proxies/speedBusiness";
import moment from "moment";

export default function WebsocketSpeed({refSpeed}){

    const [reloadWebSocket, setReloadWebSocket] = useState(0);

    useEffect(() => {
        let websocket = new WebSocket(URLWebSocketSpeed)
        let idConnexion = null;
        websocket.addEventListener("close", (event) => {
            setTimeout(function (){
                setReloadWebSocket(uuidv4());
                speedBusinessProxy.websocket.send = (cache) => {
                    console.log("ws speed hs")
                };
            },3000)

        });
        websocket.onopen = (ev) => {
            console.log('connected ws speed')
            speedBusinessProxy.websocket.send = (cache) => {
                websocket.send(JSON.stringify({
                    action : "traitementRencontre",
                    idConnexion : idConnexion,
                    refRencontresSpeedBusiness : refSpeed,
                    infoRencontre : cache?.objectCurrentPresentationSpeed
                }))
            }
            speedBusinessProxy.websocket.sendParticipant = (cache) => {
                websocket.send(JSON.stringify({
                    action : cache?.action,
                    idConnexion : idConnexion,
                    refRencontresSpeedBusiness : refSpeed,
                    idTable : cache?.idTable,
                    numTour : cache?.numTour,
                    idParticipant:cache?.idParticipant
                }))
            }
        }
        websocket.onmessage = (event) => {
            let data  = JSON.parse(event?.data)
            if (data?.action == "connexion" && data?.response?.idConnexion){
                idConnexion = data?.response?.idConnexion
                websocket.send(JSON.stringify({
                    action : "identification",
                    idConnexion : data?.response?.idConnexion,
                    refRencontresSpeedBusiness : refSpeed
                }))
            }
            else if(data?.action == "identification"){
                speedBusinessProxy.objectCurrentPresentationSpeed = data?.response?.infoRencontre;
                speedBusinessProxy.diffServeurTimestamp = data?.response?.timestamp - moment().unix();
            }
            else if (data?.action == "controlePresence"){
                websocket.send(JSON.stringify({
                    action : "controlePresence",
                    idConnexion : idConnexion
                }))
                speedBusinessProxy.diffServeurTimestamp = data?.timestamp - moment().unix();
            }
            else if(data?.action == "majEtatRencontre"){
                speedBusinessProxy.objectCurrentPresentationSpeed = data?.response?.infoRencontre;
                speedBusinessProxy.diffServeurTimestamp = data?.response?.timestamp - moment().unix();
            }else if (data?.action == "majEtatParticipant"){
                speedBusinessProxy.updateParticipantsArrive = uuidv4();
            }else if (data?.action == "participantsArrivesTour"){
                if (data?.response?.tabIdsParticipants){
                    let tmpArrivee = [];
                    Object.values(data?.response?.tabIdsParticipants).forEach(item => {
                        tmpArrivee.push(...Object.keys(item))
                    })
                    speedBusinessProxy.tabParticipantArrive = tmpArrivee;
                }
            }else if (data?.action == "participantsArrivesTable"){
                if (data?.response?.tabIdsParticipants){
                    let tmpArrivee = [];
                    Object.keys(data?.response?.tabIdsParticipants).forEach(item => {
                        tmpArrivee.push(item)
                    })
                    speedBusinessProxy.tabParticipantArriveTable = tmpArrivee;
                }
            }
        }
        return () => {
            websocket.close()
        }}, [reloadWebSocket])
    return null;
}