import {Button, Card, Dropdown} from "react-bootstrap";
import SearchBar from "../../components/SearchBar/SearchBar";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faPlus, faWrench} from "@fortawesome/free-solid-svg-icons";
import {useMemo} from "react";
import {ReactComponent as Art005} from "@assets/icons/art005.svg";
import { ReactComponent as Gen027 } from "@assets/icons/gen027.svg";
import {ReactComponent as JourJIcon} from "@assets/icons/jourJIcon.svg";
import MetaTable from "../../components/MetaTable/MetaTable";
import {useDebounce} from "use-debounce";
import {useForm} from "react-hook-form";
import speedBusinessProxy from "../../proxies/speedBusiness";
import EditModal from "./EditModal/EditModal";
import ExportParTable from "../../views/SpeedBusinessView/Exports/ExportParTable";
import useGetParticipants from "../../requests/communication/speedBusiness/useGetParticipants";
import useGetGenererParTable from "../../requests/communication/speedBusiness/useGetGenererParTable";
import useGetGenererParParticipant from "../../requests/communication/speedBusiness/useGetGenererParParticipant";
import ExportParParticipant from "../../views/SpeedBusinessView/Exports/ExportParParticipant";
import JourJModal from "./JourJModal/JourJModal";
import useDeleteSpeed from "../../requests/communication/speedBusiness/useDeleteSpeed";
import {BtSwal} from "../../utils/alerts/sweetAlert";

export default function SpeedBusinessView(){
    const { register, watch, setValue } = useForm();
    const search = watch('search');
    const colSearch =  {
    };
    const deleteSpeed = useDeleteSpeed();
    const [searchFilter] = useDebounce(search, 300);
    //todo a déplacer au bon endroit -> exemple d'utilisation + attention bug pour l'index des personnes revoir l'indexage
    const participants = useGetParticipants();
    const genererParTable = useGetGenererParTable();
    const genererParParticipant = useGetGenererParParticipant();
    const columns = useMemo(() => [
        {
            Header: () => null,
            id: 'wrench',
            Cell: ({ row }) => <Dropdown>
                <Dropdown.Toggle className='hide-after btn-icon btn-quaternaire w-30px h-30px'>
                    <FontAwesomeIcon color={"black"} icon={faWrench} />
                </Dropdown.Toggle>
                <Dropdown.Menu className='fs-6 w-350px menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-800 menu-state-bg-light-primary fw-bold py-3'>
                    <div className='menu-item px-3'>
                        <Dropdown.Item
                            className='menu-link px-3'
                            onClick={() => {
                                speedBusinessProxy.showEditSpeedBusiness(row.original)
                            }}
                        >
                            <span className='svg-icon svg-icon-1 me-2'><Art005 /></span>
                            Modifier
                        </Dropdown.Item>
                        <Dropdown.Item
                            className='menu-link px-3'
                            onClick={() => {
                                speedBusinessProxy.showJourJ(row.original)
                            }}
                        >
                            <span className='svg-icon svg-icon-1 me-2'><JourJIcon /></span>
                            Le jour J
                        </Dropdown.Item>
                    {/*    <Dropdown.Item*/}
                    {/*        className='menu-link px-3'*/}
                    {/*        onClick={async () => {*/}
                    {/*            // console.log(await participants.mutateAsync(row?.original?.id))*/}
                    {/*            ExportParTable({id :row?.original?.id,*/}
                    {/*                nomTables:row?.original?.tabNomsTables,*/}
                    {/*                participants,*/}
                    {/*                genererParTable*/}
                    {/*            });*/}
                    {/*        }}*/}
                    {/*    >*/}
                    {/*        <span className='svg-icon svg-icon-1 me-2'><Art005 /></span>*/}
                    {/*        Exporter génération par tables*/}
                    {/*    </Dropdown.Item>*/}
                    {/*    <Dropdown.Item*/}
                    {/*    className='menu-link px-3'*/}
                    {/*    onClick={async () => {*/}
                    {/*        ExportParParticipant({id :row?.original?.id,*/}
                    {/*            participants,*/}
                    {/*            genererParParticipant*/}
                    {/*        });*/}
                    {/*    }}*/}
                    {/*>*/}
                    {/*    <span className='svg-icon svg-icon-1 me-2'><Art005 /></span>*/}
                    {/*    Exporter génération par participants*/}
                    {/*</Dropdown.Item>*/}
                        <Dropdown.Item
                            className='menu-link px-3'
                            onClick={() => {
                                BtSwal.fire({
                                    title: 'Êtes vous sur de vouloir supprimer la rencontre ?',
                                    showDenyButton: true,
                                    confirmButtonText: `Oui`,
                                    denyButtonText: `Annuler`,
                                }).then((result) => {
                                    if (result.isConfirmed) {
                                        deleteSpeed.mutate(row.original.id);
                                        BtSwal.fire('La rencontre a été supprimé !', '', 'success')
                                    } else if (result.isDenied) {
                                        BtSwal.fire('La rencontre n\'a pas été supprimé', '', 'info')
                                    }
                                })

                            }}
                        >
                            <span className='svg-icon svg-icon-1 me-2'><Gen027 /></span>
                            Supprimer
                        </Dropdown.Item>
                    </div>
                </Dropdown.Menu>
            </Dropdown>,
            width: 60,
            disableResizing: true,
            disableSearch: true,
            noWrap: true,
        },
        {
            Header: 'Id',
            accessor: 'id',
            minWidth: 70,
            maxWidth: 70,
            Cell: ({ value, row }) => {
                return value
            },
        },
        {
            Header: 'Nom',
            accessor: 'nom',
            minWidth: 300,
            noWrap: true
        }
    ], []);
    return <Card>
        <Card.Body>
            <div className='d-flex flex-stack flex-wrap'>
                <SearchBar
                    solid
                    {...register('search')}
                    onClear={search?.length > 0 ? () => {
                        setValue('search', '');
                    } : null}
                />
                <div className='mb-5'>
                    <Button variant='secondary' onClick={() => {
                        speedBusinessProxy.showEditSpeedBusiness(null);
                    }}>
                        <FontAwesomeIcon icon={faPlus} className='me-2' />
                        Générer les rencontres
                    </Button>
                </div>
            </div>
            <div>
                <MetaTable
                    className='mt-5'
                    height={500}
                    url='rencontreSpeedBusiness'
                    keys={['rencontreSpeedBusiness']}
                    columns={columns}
                    search={searchFilter}
                    colSearch={colSearch}
                />
            </div>
            <EditModal/>
            <JourJModal/>
        </Card.Body>
    </Card>;

}