import React, {useEffect, useState} from "react";
import cn from "classnames";
import "./styles.css";
export default function VertilcalCarousel({data, itemHeight, isSlide = true}){
    const [activeIndex, setActiveIndex] = useState(0);

    // Used to determine which items appear above the active item
    const halfwayIndex = Math.ceil(data.length / 2);

    // Used to determine at what point an item is moved from the top to the bottom
    const shuffleThreshold = halfwayIndex * itemHeight;

    // Used to determine which items should be visible. this prevents the "ghosting" animation
    const visibleStyleThreshold = shuffleThreshold / 2;

    const determinePlacement = (itemIndex) => {
        // If these match, the item is active
        if (activeIndex === itemIndex) return 0;

        if (itemIndex >= halfwayIndex) {
            if (activeIndex > itemIndex - halfwayIndex) {
                return (itemIndex - activeIndex) * itemHeight;
            } else {
                return -(data.length + activeIndex - itemIndex) * itemHeight;
            }
        }

        if (itemIndex > activeIndex) {
            return (itemIndex - activeIndex) * itemHeight;
        }

        if (itemIndex < activeIndex) {
            if ((activeIndex - itemIndex) * itemHeight >= shuffleThreshold) {
                return (data.length - (activeIndex - itemIndex)) * itemHeight;
            }
            return -(activeIndex - itemIndex) * itemHeight;
        }
    };

    useEffect(() => {
        if (isSlide){
            const interval = setInterval(() => {
                handleClick("previous")
            }, 2000);
            return () => clearInterval(interval);
        }
    }, []);


    const handleClick = (direction) => {
        setActiveIndex((prevIndex) => {
            if (direction === "next") {
                if (prevIndex + 1 > data.length - 1) {
                    return 0;
                }
                return prevIndex + 1;
            }

            if (prevIndex - 1 < 0) {
                return data.length - 1;
            }

            return prevIndex - 1;
        });
    };

    return (
        <div className="">
            <section className="outer-container">
                <div className="carousel-wrapper">
                    <div className="carousel">

                        <div className="slides">
                            <div className="carousel-inner-custom">
                                {data.map((item, i) => (
                                    <button
                                        type="button"
                                        onClick={() => setActiveIndex(i)}
                                        className={cn("carousel-item-custom", {
                                            active: activeIndex === i,
                                            visible:
                                                data.length < 3 ? true : Math.abs(determinePlacement(i)) <= visibleStyleThreshold
                                        })}
                                        key={item.id}
                                        style={{
                                            transform: `translateY(${determinePlacement(i)}px)`
                                        }}
                                    >
                                        {item}
                                    </button>
                                ))}
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    );
}