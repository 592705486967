import {forwardRef} from "react";
import {Button} from "react-bootstrap";
import {useSnapshot} from "valtio";
import speedBusinessProxy from "../../../../proxies/speedBusiness";
import auth from "../../../../services/auth";

const Lancement = forwardRef((_, ref) => {

    const speedSnap = useSnapshot(speedBusinessProxy);
    const snapAuth = useSnapshot(auth);

    return <div>
        <div className="d-flex justify-content-center">
            <Button onClick={() => {
                if (speedSnap?.speedBusiness?.ref){
                    let base_url = window.location.origin;
                    window.open(base_url+"/"+snapAuth?.agence?.dossierUpload+"/speedBusiness/presentation?ref="+speedSnap.speedBusiness.ref, '_blank');
                }
            }} variant="primary">
                Go
            </Button>
            <Button variant="danger" className="ms-3">
                Envoyer l'email aux présents
            </Button>
            <Button variant="secondary" className="ms-3">
                Envoyer le sms aux présents
            </Button>
        </div>
        {/*
            // todo rajouter la gestion des excels
        */}
    </div>
})

export default Lancement;