import { useQuery } from "react-query";
import apiPublic from "@services/apiPublic";

export default function useGetSpeedFromRef(ref) {
        return useQuery(
            ['rencontreSpeedBusiness', ref],
            async () => {
                return apiPublic.get(`rencontreSpeedBusiness/fromRef?ref=`+ref).json();
            },
            {
                enabled: ref != null,
            },
        );
}